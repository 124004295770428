:root {
  /* Color System - Primary Colors */
  --primary: #FF0066;
  --primary-hover: #FF1A75;
  --primary-focus: #FF3385;
  --primary-glow: rgba(255, 0, 102, 0.5);
  
  --secondary: #FF3D00;
  --secondary-hover: #FF5719;
  --secondary-focus: #FF7033;
  --secondary-glow: rgba(255, 61, 0, 0.5);
  
  --accent: #FF1493;
  --accent-hover: #FF2D9D;
  --accent-focus: #FF47A7;
  --accent-glow: rgba(255, 20, 147, 0.5);
  
  /* Background Colors */
  --background: #0A0A0F;
  --background-light: #12121F;
  --background-lighter: #1A1A2F;
  
  --surface: #12121F;
  --surface-light: #1A1A2F;
  --surface-hover: #22223F;
  --surface-focus: #2A2A4F;
  
  /* Text Colors - Updated for better visibility */
  --text: #FFFFFF;
  --text-secondary: #E5E5E5;
  --text-muted: #A3A3A3;
  
  /* Status Colors */
  --success: #00FF9F;
  --success-light: rgba(0, 255, 159, 0.1);
  --success-glow: rgba(0, 255, 159, 0.5);
  
  --warning: #FFB800;
  --warning-light: rgba(255, 184, 0, 0.1);
  --warning-glow: rgba(255, 184, 0, 0.5);
  
  --error: #FF0033;
  --error-light: rgba(255, 0, 51, 0.1);
  --error-glow: rgba(255, 0, 51, 0.5);
  
  --info: #00BFFF;
  --info-light: rgba(0, 191, 255, 0.1);
  --info-glow: rgba(0, 191, 255, 0.5);
  
  /* Spacing */
  --spacing-px: 1px;
  --spacing-0: 0;
  --spacing-1: 0.25rem;
  --spacing-2: 0.5rem;
  --spacing-3: 0.75rem;
  --spacing-4: 1rem;
  --spacing-5: 1.25rem;
  --spacing-6: 1.5rem;
  --spacing-8: 2rem;
  --spacing-10: 2.5rem;
  --spacing-12: 3rem;
  --spacing-16: 4rem;
  --spacing-20: 5rem;
  --spacing-24: 6rem;
  
  /* Typography */
  --font-sans: 'Poppins', system-ui, -apple-system, sans-serif;
  --font-display: 'Orbitron', sans-serif;
  
  /* Transitions */
  --transition-fast: 150ms cubic-bezier(0.4, 0, 0.2, 1);
  --transition-normal: 300ms cubic-bezier(0.4, 0, 0.2, 1);
  --transition-slow: 500ms cubic-bezier(0.4, 0, 0.2, 1);
  
  /* Z-index */
  --z-negative: -1;
  --z-elevate: 1;
  --z-sticky: 100;
  --z-drawer: 200;
  --z-modal: 300;
  --z-popover: 400;
  --z-maximum: 999;
}