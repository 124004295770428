/* src/styles/global.css */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&family=Orbitron:wght@400;500;600;700&display=swap');
@import './variables.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply bg-background text-white font-sans;
    overflow-x: hidden;
  }

  h1, h2, h3, h4, h5, h6 {
    @apply font-display text-white font-bold;
  }

  p {
    @apply text-gray-100;
  }
}

@layer components {
  /* Button Styles */
  .btn-primary {
    @apply relative overflow-hidden px-6 py-3 rounded-lg font-semibold
           bg-gradient-to-r from-[#FF0066] to-[#FF3D00] text-white
           hover:shadow-lg
           active:scale-95
           transition-all duration-300;
  }
  
  .btn-secondary {
    @apply relative overflow-hidden px-6 py-3 rounded-lg font-semibold
           bg-surface text-white border border-[#FF0066]
           hover:border-[#FF0066] hover:text-white
           active:scale-95
           transition-all duration-300;
  }

  /* Card Styles */
  .card-cyber {
    @apply relative overflow-hidden
           bg-surface rounded-lg text-white
           border border-[#FF0066]
           backdrop-blur
           hover:scale-[1.02] hover:border-[#FF0066] hover:shadow-lg
           transition-all duration-300;
  }

  /* Glass Effects */
  .glass {
    @apply border border-[#FF0066] backdrop-blur text-white bg-[rgba(18,18,31,0.5)];
  }

  .glass-primary {
    @apply border border-[#FF0066] backdrop-blur text-white bg-[rgba(255,0,102,0.1)];
  }

  .glass-secondary {
    @apply border border-[#FF3D00] backdrop-blur text-white bg-[rgba(255,61,0,0.1)];
  }

  /* Text Effects */
  .text-glow {
    @apply text-white;
    text-shadow: 0 0 5px rgba(255, 0, 102, 0.5);
  }

  .gradient-text {
    @apply text-transparent bg-clip-text;
    background-image: linear-gradient(to right, #FF99CC, #FFFFFF, #FF99CC);
  }

  /* Layout Components */
  .container {
    @apply max-w-7xl mx-auto px-4;
  }

  .section-cyber {
    @apply relative py-20 overflow-hidden;
    background: linear-gradient(to bottom right, var(--background), var(--surface));
  }

  /* Navigation */
  .nav-link {
    @apply text-gray-300 hover:text-white transition-colors duration-300;
  }

  .nav-link-active {
    @apply text-white;
  }

  /* Form Elements */
  .input-cyber {
    @apply bg-surface border border-[#FF0066] rounded-lg px-4 py-3
           text-white placeholder:text-gray-400
           focus:border-[#FF0066] focus:ring-1 focus:ring-[#FF0066]
           outline-none transition-all;
  }

  .select-cyber {
    @apply bg-surface border border-[#FF0066] rounded-lg px-4 py-3
           text-white
           focus:border-[#FF0066] focus:ring-1 focus:ring-[#FF0066]
           outline-none transition-all;
  }

  /* Typography */
  .cyber-heading {
    @apply text-3xl md:text-4xl lg:text-5xl font-display font-bold text-white;
  }

  .cyber-subheading {
    @apply text-xl md:text-2xl lg:text-3xl font-display text-white;
  }

  /* Custom Background Styles */
  .bg-gradient-cyber {
    background: linear-gradient(to right, rgba(255, 0, 102, 0.1), rgba(255, 61, 0, 0.1));
  }

  .rounded-cyber {
    @apply relative overflow-hidden;
    &::before {
      content: '';
      @apply absolute inset-0 bg-gradient-cyber opacity-20;
    }
  }
}

/* Background Patterns */
.cyber-dots {
  background-image: radial-gradient(circle, rgba(255, 0, 102, 0.1) 1px, transparent 1px);
  background-size: 20px 20px;
}

.cyber-stripes {
  background: repeating-linear-gradient(
    45deg,
    rgba(255, 0, 102, 0.1) 0%,
    rgba(255, 0, 102, 0.1) 1px,
    transparent 1px,
    transparent 50%
  );
  background-size: 10px 10px;
}

/* Animations */
@keyframes float {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
}

@keyframes glitch {
  0% { transform: translate(0); }
  20% { transform: translate(-2px, 2px); }
  40% { transform: translate(-2px, -2px); }
  60% { transform: translate(2px, 2px); }
  80% { transform: translate(2px, -2px); }
  100% { transform: translate(0); }
}

@keyframes shimmer {
  0% { transform: translateX(-100%); }
  100% { transform: translateX(100%); }
}

.animate-float {
  animation: float 3s ease-in-out infinite;
}

.animate-glitch {
  animation: glitch 0.5s steps(1) infinite;
}

.animate-shimmer {
  animation: shimmer 1.5s infinite;
}

/* Animation Delays */
.animate-delay-100 { animation-delay: 100ms; }
.animate-delay-200 { animation-delay: 200ms; }
.animate-delay-300 { animation-delay: 300ms; }

/* Scrollbar Styles */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: var(--background);
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(to bottom, #FF0066, #FF3D00);
  border-radius: 9999px;
}

/* Hover Effects */
.hover-glow {
  transition: box-shadow 0.3s ease;
}

.hover-glow:hover {
  box-shadow: 0 0 20px rgba(255, 0, 102, 0.3);
}

/* Icon Backgrounds */
.icon-bg {
  @apply flex items-center justify-center rounded-full bg-[rgba(255,0,102,0.1)] p-3;
}

/* Text Styles */
.text-balance {
  text-wrap: balance;
}

.text-pretty {
  text-wrap: pretty;
}